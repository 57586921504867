.badge {
  line-height: 16px !important;
}

.badge>span.anticon {
  line-height: 16px !important;
}

.badge>sup {
  padding: 0px 4px !important;
}

.ant-layout-has-sider>button {
  top: 0 !important;
  border-top-right-radius: 0 !important;
}