.content {
  width: 100%;
}

.layout {
  height: 100vh;
}

.card {
  width: 350px;
  height: 218px;
  /* background-color: rgb(240, 243, 248); */
}

.card .ant-card-head-title {
  padding: 12px 0 8px;
}


.card button {
  color: white;
}

.sber {
  background-color: rgb(33, 160, 56);
}

.tink {
  background: linear-gradient(to right, rgb(29, 37, 60), rgb(62, 71, 87));
}

.sber .ant-typography {
  color: white;
}

.tink .ant-typography {
  color: white;
}

.loading-space {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}