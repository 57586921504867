.list-sort-demo-wrapper {
  position: relative;
  overflow: hidden;
}

.list-sort-demo {
  margin: 40px auto;
  width: 90%;
  cursor: grab;
  position: relative;
}

.list-sort-demo>div {
  overflow: hidden;
}

.list-sort-demo-list {
  color: black;
  background-color: lightgray;
  border-radius: 6px;
  margin: 5px auto;
  padding: 10px;
  transition: box-shadow .5s, transform .5s;
}

.list-sort-demo-list.list-drag-selected {
  box-shadow: 0 8px 20px grey;
  transform: scale(1.03) !important;
}

.list-sort-demo-text {
  width: 80%;
  display: inline-block;
}

.list-sort-demo-text h1 {
  font-size: 18px;
}

.list-sort-demo-text p {
  font-size: 12px;
}

@media screen and (max-width: 320px) {
  .list-sort-demo-text h1 {
    font-size: 14px;
    line-height: 28px;
  }
}